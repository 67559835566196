import React from "react";
import '../styles/long1.css';
import '../styles/shortForm.css';
const short2Page = () => (
    <div class="grid-container">
    <div class="left_space"></div>
        <div class="short1-main">
        <div class="long2-main1 shortpage-bg-image">
            <div className="logo-div">
                    <a aria-current="page" className="logo" href="/"><div className="logo-image"></div>
                    </a></div>
                    <div className="nav-belt register-header-section">
                        <div className="wp-block-column grid-column-parent">
                            <h3 className="register-header-title">Complete your MyTEXpress profile</h3>
                            <p className="register-header-text">You're saving time by using the LBJ, NTE &amp; NTE 35W TEXpress Lanes. Find out how much by completing your profile today.</p>
                        </div>
                        <div className="wp-block-column grid-column-children long2-form-section">
                            <div id='lightbox-inline-form-6fe78ee2-5087-4af7-97ac-305857418869' class="register-formBorder"></div>
                        </div>
                </div>
        </div>
        <div class="long2-main2">
                <a href='https://www.texpresslanes.com/faq-page' target='_blank'><p className="faq">MyTEXpress FAQs</p></a>
            </div>
    </div>  
    <div class="right_space"></div>
    <div class="footer">
            <div class="register-footer1"><a href="https://www.facebook.com/TEXpressLanes" target="_blank"><img class="fb-icon"
  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"/></a> | <a href="https://twitter.com/texpresslanes" target="_blank"><img class="twitter-icon"
  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"/></a> |  <a href="https://www.texpresslanes.com/privacy-policy" target="_blank">Privacy Policy</a> │ ©2020 LBJ/NTE/NTE 35W TEXpress Lanes | All Rights Reserved</div>
        </div>
    </div>
);

export default short2Page;
